:root {
  --dialog-padding: 40px;

  @media screen and (max-width: 768px) {
    --dialog-padding: 20px;
  }
}

.BDialog {
  font-size: var(--new-design-font-size-md);
}

/** .MuiDialogTitle-root */
.BDialog :global(.MuiDialog-paper) > :first-child {
  padding-top: var(--dialog-padding);
}
/** .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root */
.BDialog :global(.MuiDialog-paper) > * {
  padding-left: var(--dialog-padding);
  padding-right: var(--dialog-padding);
}
/** .MuiDialogActions-root */
.BDialog :global(.MuiDialog-paper) > :last-child {
  padding-bottom: var(--dialog-padding);
}

.largerScrollArea > * > :global(.MuiPaper-root) {
  overflow: auto;
}
.largerScrollArea > * > :global(.MuiPaper-root) :global(.MuiDialogContent-root) {
  overflow-y: initial;
}

.BDialog :global(.MuiTypography-h6) {
  font-size: 1.25em;
}

.BDialog :global(.MuiPaper-root) {
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    margin: 0px;
  }
}
